import React, { useState } from "react";
import useCurrentWidth from "./useCurrentWidth";
import { Fetcher } from "../Utils/AxiosWrapper";
import Modal from "react-bootstrap/Modal"

export default function PasswordResetc() {
  const width = useCurrentWidth();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const [code, setCode] = useState(urlParams.get("code") ? urlParams.get("code") : "");
  const [showSuccess, setShowSuccess] = useState(false);
  const [error, setError] = useState("");
  return (
    <>
    <div style={{color: `black`, minHeight: `100vh`, display: `flex`}}>
      <div style={{display: `flex`, justifyContent: `center`, alignItems: `center`}} onClick={() => {window.location.replace("/")}}>
        <img style={{padding: (width < 700) ? `0px 6vw` : `7vw`, width: `48vw`, height: (width < 700) ? `40vw` : `50vw`}} 
      src={(width < 700) ? "/Images/ReviewHeart.png" : "/Images/LoginLeft.png"}></img></div>
      <div style={{width: `5px`, minHeight: `100vh`, backgroundColor: `#e37100`}}/>
      <div style={{width: `49vw`}}><div style={{display: `flex`, justifyContent: `center`, alignItems: `center`}}>
        <img src="/Images/BottomLogo.png" style={{width: (width < 700) ? `41vw` : `20vw`, height: (width < 700) ? `28vw` : `14vw`, marginTop: `20vh`}}></img></div>
      <div style={{display: `flex`, justifyContent: `center`, alignItems: `center`, height: (width < 375) ? `65vh` : `50vh`}}>
        <div style={{color: `black`, fontSize: (width < 700) ? `12px` : `16px`, fontWeight: `800`}}>
        <div className="login" style={{width: (width < 700) ? `41vw` : `28vw`}}>
        {!urlParams.get("code") && <div style={{marginBottom: `10px`}}><div>Code (from email)</div>
        <input  type="text" id="code" name="code" value={code} onChange={(e) => {setError(""); setCode(e.target.value);}}/></div>}
        <div>New Password</div>
        <input  type="password" id="password" name="password" onChange={() => {setError("")}}/> 
        <div style={{marginTop: `10px`}}>Confirm New Password</div>
        <input  type="password" id="confirmPassword" name="confirmPassword"  onChange={() => {setError("")}}/>
        <div style={{marginTop: `10px`, color: `red`}}>{error}</div>
        <button style={{ cursor: `pointer`, marginTop: `50px`, textTransform: `uppercase`}}
        onClick={() => { 
            if (document.getElementById("password").value != document.getElementById("confirmPassword").value)
            {
                setError("Password and Confirm Password must match.");
            } 
            else{
                Fetcher("POST", "/api/CodeVerification", {code: code, password: document.getElementById("password").value})
                .then(() => {
                    setShowSuccess(!showSuccess);
                })
                .catch((err) => {
                    if (err.response && err.response.data && err.response.data.Message)
                    {
                      setError(err.response.data.Message);
                    }
                    else{
                      setError("error: "+JSON.stringify(err));
                    }
                });
            }
        }}
        >Submit</button>
        <div style={{marginTop:`10px`, fontSize: `13px`, float: `right`, cursor: `pointer`, }}
        onClick={() => {window.location.href="/register"}}>Register</div>
        </div>
        </div>
        </div>
      </div>
    </div>
    <Modal
        className="termsModal"
        style={{ border: `none` }}
        show={showSuccess}
        onHide={() => {window.location.replace("/login")}}
        centered
        scrollable={true}
      >
        <Modal.Header
          style={{
            backgroundImage: `linear-gradient(red, rgb(252,195,10))`,
            height: `40px`,
            fontWeight: `bold`,
            color: `white`,
            border: `none`,
            
          }}
          closeButton={false}
        >
          <Modal.Title className="modalHeader">
            Password Change Successful
          </Modal.Title>
          <img
            style={{
              marginTop: `-6px`,
              marginRight: `-10px`,
              cursor: "pointer",
              height: `20px`,
            }}
            onClick={() => {window.location.replace("/login")}}
            src={window.location.origin + "/Images/closeTerms.png"}
            alt="close button"
          />
        </Modal.Header>
        <Modal.Body
          style={{
            background: `linear-gradient(rgba(255, 100, 0, 0.2), rgba(252, 195, 10, 0.2))`,
            border: `none`,
            maxHeight: `80vh`,
            overflowY: `auto`,
          }}
          className="loginModalBody"
        >
          <p>You have successfully changed your email. Please select ok to reroute to login.</p>
          <button style={{color: `white`, borderRadius: `10px`, cursor: `pointer`, marginTop: `20px`, textTransform: `uppercase`, float: `right`}} onClick={() => {window.location.replace("/login")}}>OK</button>
        </Modal.Body>
      </Modal>
    </>
  )
}
