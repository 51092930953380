import React from "react";
import Layout from "../Components/layout";
import PasswordResetc from "../Components/passwordResetc";
import { Seo } from "../Components/seo";


export default function Passwordreset() {
  return (
    <Layout>
      <PasswordResetc />
    </Layout>
  )
}

export const Head = () => (
  <Seo />
)